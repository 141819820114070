//Fixed height on Ipads & smaller devices ( IOS devices - 100vh changes on scroll )
export function vhToPx( element, height ) {
	if ( window.innerWidth > 450 ) {
		var new_height = ( window.innerHeight * height );
		element.style.height = new_height + 'px';
	} else {
		element.style.height = 350 + 'px';
	}
}
export function applyVhToPx( elements, height ) {
	elements.forEach( function( element ) {
		for ( var i = 0; i < element.length; i++ ) {
			vhToPx( element[i], height );
		}
	} );
	var window_width = window.innerWidth;
	window.addEventListener( 'resize', function() {
		var new_window_width = window.innerWidth;
		if ( window_width !== new_window_width ) {
			elements.forEach( function( element ) {
				for ( var i = 0; i < element.length; i++ ) {
					vhToPx( element[i], height );
				}
			} );
			window_width = new_window_width;
		}
	} );
}
