( function(){
	'use strict';
	var links = document.querySelectorAll( '.lightbox' );

	if ( links.length === 0 ) {
		return;
	}

	var current_link_id = 0;
	var lightbox = document.createElement( 'div' );
	var lightbox_spinner = document.createElement( 'div' );
	var lightbox_inner = document.createElement( 'div' );
	var lightbox_close = document.createElement( 'button' );
	var lightbox_close_span1 = document.createElement( 'span' );
	var lightbox_close_span2 = document.createElement( 'span' );
	var lightbox_close_span3 = document.createElement( 'span' );
	var p = document.createElement( 'div' );

	lightbox.className = 'lightbox__frame disabled';
	lightbox_spinner.className = 'lightbox__spinner';
	lightbox_inner.className = 'lightbox__inner';
	lightbox_close.className = 'lightbox__close';
	lightbox_close.setAttribute( 'aria-label', 'Close lightbox' );
	lightbox.appendChild( lightbox_spinner );
	lightbox.appendChild( lightbox_inner );
	lightbox.appendChild( lightbox_close );
	lightbox_close.appendChild( lightbox_close_span1 );
	lightbox_close.appendChild( lightbox_close_span2 );
	lightbox_close.appendChild( lightbox_close_span3 );
	document.body.insertBefore( lightbox, document.body.firstChild );

	lightbox.addEventListener( 'click', function( event ){
		if ( event.target === lightbox ){
			hideLightBox();
		}
	} );
	lightbox_close.addEventListener( 'click', function( event ) {
		hideLightBox();
	} );
	window.addEventListener( 'resize', function( event ) {
		if ( lightbox.classList.contains( 'disabled' ) ) {
			return;
		}
		updateLightBoxContent();
	} );
	document.addEventListener( 'keyup', function( event ) {
		if ( event.keyCode === 27 ){ // Escape
			hideLightBox();
		}
	} );

	for ( var i = 0; i < links.length; i += 1 ) {
		links[i].setAttribute( 'data-slide-id', i.toString() );
		links[i].addEventListener( 'click', handleClick( links[i] ) );
	}

	function slideFromContent( type, content, loaded_callback ) {
		var element;
		switch( type ) {
			case 'iframe':
				element = document.createElement( 'div' );
				var iframe = document.createElement( 'iframe' );
				element.className = 'flex-container';
				element.appendChild( iframe );
				iframe.frameborder = 0;
				iframe.allowfullscreen = true;
				iframe.src = content;
				loaded_callback( element );
				break;
		}
		return element;
	}

	function handleLightBoxLink( link ){
		current_link_id = parseInt( link.getAttribute( 'data-slide-id' ), 10 );
		updateLightBoxContent();
		showLightBox();
	}

	function clearLightBox(){
		lightbox_inner.innerHTML = '';
	}

	function updateLightBoxContent(){
		lightbox.classList.add( 'loading' );
		slideFromContent( links[current_link_id].getAttribute( 'data-lightbox-type' ), links[current_link_id].getAttribute( 'href' ), function( content ) {
			clearLightBox();
			lightbox_inner.appendChild( content );
			centreLightBoxContent( content );
			lightbox.classList.remove( 'loading' );
		} );
	}

	function centreLightBoxContent( content ){
		var window_height = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
		var max_slide_height = window_height - 60;
		lightbox_inner.style.maxWidth = '';
		if ( content.offsetHeight > max_slide_height ) {
			var aspect_ratio = content.offsetWidth / content.offsetHeight;
			lightbox_inner.style.maxWidth = ( aspect_ratio * max_slide_height ).toString() + 'px';
		}
		lightbox_inner.style.marginTop = Math.round( ( window_height - content.offsetHeight ) / 2 ).toString() + 'px';
	}

	function showLightBox() {
		lightbox.classList.remove( 'disabled' );
	}

	function hideLightBox() {
		lightbox.classList.add( 'disabled' );
		window.setTimeout( function() {
			clearLightBox();
		}, 10 );
	}

	function handleClick( link ) {
		return function( event ) {
			if ( event.preventDefault ) {
				event.preventDefault();
			}
			else {
				event.returnValue = false;
			}
			handleLightBoxLink( link );
		};
	}

} )();
