/* globals ajax, sendTrackingEvent */
import './vendor/ajax.min.js';
import './vendor/ga-events.js';
import { isIE } from './vendor/isie.js';
import './vendor/lightbox.js';
import './vendor/slideshow.js';
export { vhToPx, applyVhToPx } from './vendor/vhtopx.js';

import './vendor/polyfills/nextsibling.js';
import './vendor/polyfills/classlist.min.js';
import './vendor/polyfills/eventlistener.min.js';

import './vendor-solo/html5shiv.min.js';

( () => {

	( () => {
		// Cookie notice
		if ( document.cookie.indexOf( 'seenCookieNotice' ) === -1 ) {
			const cookie_notice = document.querySelector( '.cookie-notice' );
			document.cookie = 'seenCookieNotice=yes;path=/;max-age=31536000';
			document.body.classList.add( 'show-cookie-notice' );
			document.querySelector( '.cookie-notice button' ).addEventListener( 'click', () => {
				cookie_notice.style.marginTop = - cookie_notice.offsetHeight + 'px';
				window.setTimeout( () => {
					document.body.classList.remove( 'show-cookie-notice' );
				}, 200 );
			} );
		}
	} )();

	( () => {
		// Mobile nav
		const nav_toggle = document.querySelector( '.mobile-nav-toggle' );
		nav_toggle.addEventListener( 'click', () => {
			document.body.classList.toggle( 'nav-open' );
		} );
	} )();

	( () => {
		// Search
		const open_search = document.querySelector( '.nav__item__search' );
		const open_search_mobile = document.querySelector( '.header__mobile-search' );
		const close_search = document.querySelector( '.search-overlay__close' );
		const search_input = document.querySelector( '.search-overlay input' );

		open_search_mobile.addEventListener( 'click', () => {
			openSearch();
		} );
		open_search.addEventListener( 'click', () => {
			openSearch();
		} );
		close_search.addEventListener( 'click', () => {
			document.body.classList.remove( 'search-open' );
		} );

		function openSearch() {
			document.body.classList.add( 'search-open' );
			search_input.focus();
			window.addEventListener( 'resize', () => {
				search_input.scrollIntoView();
				search_input.scrollIntoViewIfNeeded();
			} );
		}
	} )();

	( () => {
		// Fade in listing images
		const int = 200;
		let items = document.querySelectorAll( '.is-hidden' );

		function isElementInViewport( element ) {
			const rect = element.getBoundingClientRect();
			return (
				rect.top <= ( ( window.innerHeight || document.documentElement.clientHeight ) + 20 )
			);
		}

		function checkItemsInViewport() {
			items = document.querySelectorAll( '.is-hidden' );
			for ( let i = 0; i < items.length; i += 1 ) {
				if ( isElementInViewport( items[i] ) ) {
					setTimeout( ( ( element ) => {
						return () => {
							element.classList.remove( 'is-hidden' );
						};
					} )( items[i] ), ( i + 0.1 ) * int );
					continue;
				}
			}
		}

		let debounced = false;
		checkItemsInViewport();
		window.addEventListener( 'scroll', () => {
			if ( !debounced ) {
				checkItemsInViewport();
				debounced = true;
				window.setTimeout( () => {
					debounced = false;
				}, int );
			}
		} );

		checkItemsInViewport();
	} )();

	( () => {
		// Async feeds
		function handleAjaxSuccess( feed_container, feed_content ) {
			return ( data ) => {
				feed_container.classList.add( 'external-feed--loaded' );
				feed_content.innerHTML = data;
			};
		}

		function handleAjaxError( feed_container, feed_content ) {
			return () => {
				feed_container.classList.add( 'external-feed--loaded' );
				feed_content.innerHTML = '<p class="p">Feed failed to load.</p>';
			};
		}

		const feed_containers = document.querySelectorAll( '.external-feed' );
		const feed_content = document.querySelectorAll( '.external-feed__content' );
		for ( let i = 0; i < feed_containers.length; i += 1 ) {
			const url = feed_containers[i].getAttribute( 'data-feed-url' );
			if ( !url ) {
				continue;
			}
			ajax( {
				type: 'get',
				url: url,
				timeout: 8000,
				onSuccess: handleAjaxSuccess( feed_containers[i], feed_content[i] ),
				onError: handleAjaxError( feed_containers[i], feed_content[i] )
			} );
		}

	} )();

	( () => {
		//CSS fallbacks
		const images = document.querySelectorAll( '.main-image' );
		const blur_images = document.querySelectorAll( '.main-image-blur' );

		if ( 'objectFit' in document.documentElement.style === false ) {
			//Object-Fit fallback
			for ( let x = 0; x < blur_images.length; x++ ) {
				swapImageToBg( blur_images[x] );
			}
			for ( let i = 0; i < images.length; i++ ) {
				swapImageToBg( images[i], true );
			}
		}

		function swapImageToBg( image, top ) {
			const image_source = image.getAttribute( 'src' );
			image.classList.add( 'hidden' );
			image.parentNode.style.backgroundImage = 'url(' + image_source + ')';
			image.parentNode.classList.add( 'main-image-fallback' );
			if ( top ) {
				image.parentNode.classList.add( 'main-image-fallback--top' );
			} else {
				image.parentNode.classList.add( 'main-image-fallback--blur' );
			}
		}

		//Blur Filter
		if ( document.documentElement.style.filter === '' && isIE() ) {
			for ( let y = 0; y < blur_images.length; y++ ) {
				blur_images[y].classList.add( 'hidden' );
				blur_images[y].parentNode.classList.add( 'hidden' );
			}
		}

	} )();

	( () => {
		//Send GA events
		const ga_links = document.querySelectorAll( '[data-ga-category]' );

		for ( let i = 0; i < ga_links.length; i++ ) {
			ga_links[i].addEventListener( 'click', sendTrackingEvent( ga_links[i] ) );
		}
	} )();


} )();
